import React from 'react';
import { Link } from 'gatsby';
import { Container, Box, Typography } from '@material-ui/core';
import {AppBar, Tabs, Tab} from '@material-ui/core'
import config from '../../data/SiteConfig'
import Categories from './Categories'
import styles from './Header.module.scss'
import TroopLogos from './TroopLogos'
import img from 'gatsby'

const Header = () => (
  <header>
            
  
    <nav>
      <ul className={styles.mainNav}>
             <li> <TroopLogos /></li>
       
          <li>
        
        <Link to="/" activeClassName={styles.activeNav}
        >  
    Home
              
            </Link>
          
                </li>   
       <li>
	<Link to="/annual" activeClassName={styles.activeNav}>
	 Events
	</Link>
       </li>
       <li>
	<Link to="/join" activeClassName={styles.activeNav}>
	 Join
	</Link>
       </li>
       <li>
	<Link to="https://www.troopwebhost.org/Troop622Bothell/Index.htm" activeClassName={styles.activeNav}>
Members
	</Link>
       </li>
      </ul>
     
    </nav>
  </header>
)

export default Header

