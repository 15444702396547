import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import config from '../../data/SiteConfig'

export default function TroopLogos() {
  return (
  <StaticQuery
  query = {graphql`
   query {
   image1: file(relativePath: { eq: "gallery/troop622.png" }) {
     childImageSharp {
       fluid(maxWidth:100){
         ...GatsbyImageSharpFluid
         }
         }
         }
    image2: file(relativePath: { eq: "gallery/trooplogo-transparent.png" }) {
      childImageSharp {
       fluid(maxWidth: 270) {
        ...GatsbyImageSharpFluid
        }
        }
        }
         }
        `}
  render={data => (
    <span>
    <Img fluid={data.image1.childImageSharp.fluid}
         style={{
          maxWidth: "100px",
          width: "100px",
          display: "inline-block",
          "vertical-align": "middle",
        }} />
    </span>
  )}
  />
  )
  }
  
